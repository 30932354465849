<template>
    <div v-if="row.item" class="o365-body-row" :data-o365-rowindex="rowIndex"
        :class="[{ 'active': row.item.current && activeRows, 'selected': row.item.isSelected, 'dirty': row.item.hasChanges && row.item.disableSaving}, rowClass, { 'd-none': row.isLoading, 'o365-group-row': row.item.o_groupHeaderRow || row.item.o_hasDetails }]"
        :style="[{ height: row.rowHeight + 'px', 'transform': 'translateY(' + row.pos + 'px)', transition: row.transition }, rowStyle]">
        <template v-if="dataGridControl.props.enableVirtualColumns && !props.pinned">
            <template v-for="(col) in columns" :key="col.colId">
                <template v-if="col.item && !col.item.hide && col.item.pinned == pinned">
                    <ScopedCell :x="col.item.order" :y="rowIndex" :getSelectionClass="getSelectionClass">
                        <template #default="{selectionClass}">
                            <ODataGridBodyCell :col="col.item" :row="row" :colIndex="col.item.order" :disabled="!dataGridControl.state.allowUpdate"
                                :selectionClass="selectionClass" :isTable="isTable" :features="gridFeatures"
                                :handleSelection="(pEvent) => handleSelection(pEvent, row)"
                                :is-active-edit-cell="activeEditLocation === `${container}_${col.item.order}_${rowIndex}`">
                                <template #editor>
                                    <InlineCellEditor :ref="cmp => updateEditorRef(cmp, col.item.order + '_' + rowIndex)" :row="row?.item"
                                        :column="col.item" :gridContainer="viewportRef" :dataGridControl="dataGridControl"
                                        :rowIndex="rowIndex" />
                                </template>
                            </ODataGridBodyCell>
                        </template>
                    </ScopedCell>
                </template>
            </template>
        </template>
        <template v-else>
            <template v-for="(col) in columns" :key="col.colId">
                <template v-if="!col.hide && col.pinned == pinned">
                    <ScopedCell :x="col.order" :y="rowIndex" :getSelectionClass="getSelectionClass">
                        <template #default="{selectionClass}">
                            <ODataGridBodyCell :col="col" :row="row" :colIndex="col.order" :disabled="!dataGridControl.state.allowUpdate"
                                :selectionClass="selectionClass" :isTable="isTable" :features="gridFeatures"
                                :handleSelection="(pEvent) => handleSelection(pEvent, row)"
                                :is-active-edit-cell="activeEditLocation === `${container}_${col.order}_${rowIndex}`">
                                <template #editor>
                                    <InlineCellEditor :ref="cmp => updateEditorRef(cmp, col.order + '_' + rowIndex)" :row="row?.item"
                                        :column="col" :gridContainer="viewportRef" :dataGridControl="dataGridControl"
                                        :rowIndex="rowIndex" />
                                </template>
                            </ODataGridBodyCell>
                        </template>
                    </ScopedCell>
                </template>
            </template>
        </template>
        <slot name="overlay" :row="row.item"></slot>
    </div>
</template>

<script lang="ts">

const ScopedCell = defineComponent({
    name: 'ScopedCell',
    props: ['getSelectionClass','x','y'],
    setup(props, ctx) {
        const selectionClass = computed(() => {
            return props.getSelectionClass(props.x, props.y);
        });
        const selValue = ref([]);
        watch(selectionClass, (newVal, oldVal) => {
            selValue.value = newVal;
        }, {
            immediate: true
        });
        return () => ctx.slots.default({selectionClass: selValue.value});
    }
});

export interface IProps {
    row: ScrollItem,
    pinned?: 'left' | 'right', 
    rowIndex: number,
    rowClass: any,
    rowStyle: any,
    getSelectionClass: (x: number, y: number) => string | undefined,
    updateEditorRef: (cmp: any, loc: string) => void,
    container: string,
    activeEditLocation?: string | null,
    viewportRef: any,
    isTable?: boolean,
    activeRows?: boolean
};
</script>

<script setup lang="ts">
import type { ScrollItem } from 'o365-vue-utils';
import type DataGridContorl from './DataGridControl.ts';
import type { Ref } from 'vue';

import { ODataGridBodyCell } from './helpers.jsx';
import InlineCellEditor from './components.InlineCellEditor.vue';
import { InjectionKeys } from 'o365-utils';
import { computed, inject, ref, watch, defineComponent } from 'vue';
import useVirtualColumns from './composables.ColumnVirtualization.ts';

const props = defineProps<IProps>();

const dataGridControl = inject<Ref<DataGridContorl>>(InjectionKeys.dataGridControlKey);
if (dataGridControl == null || dataGridControl.value == null) {
    throw new Error('DataGridBodRow must be inside a DataGrid');
}

const virtualColumns = dataGridControl.value.props.enableVirtualColumns
    ? useVirtualColumns()
    : {};

const gridFeatures = computed(() => {
    const features: string[] = [];
    if (dataGridControl.value.hasRowDrag) {
        let allowDrag = true;
        if (props.row.item == null) {
            allowDrag = false;
        } else if (dataGridControl.value.props.rowDragOptions && dataGridControl.value.props.rowDragOptions.isRowDraggable) {
            allowDrag = dataGridControl.value.props.rowDragOptions.isRowDraggable(props.row.item);
        }
        if (allowDrag && (dataGridControl.value.rowDrag?.canDrag || dataGridControl.value.hasRowDrag2)) {
            features.push('rowdrag');
        }
    }
    return features;
});

const columns = computed(() => {
    if (dataGridControl.value.isTable) {
        return dataGridControl.value.dataColumns.columns;
    }

    if (virtualColumns.scrollData && !props.pinned) {
        return virtualColumns.scrollData.value;
        // return virtualColumns.scrollData.value.map(x => x.item);
    }

    switch (props.pinned) {
        case 'left':
            return dataGridControl.value.dataColumns.leftColumns;
        case 'right':
            return dataGridControl.value.dataColumns.rightColumns;
        default:
            return dataGridControl.value.dataColumns.centerColumns;
    }
});

function handleSelection(pEvent: MouseEvent, pRow: ScrollItem) {
    // if (pRow.item) {
        // pRow.item.isSelected = !(pRow.item.isSelected ?? false);
    // }
    dataGridControl.value.handleScrollItemMultiSelect(pEvent, pRow);
    // data ? dataGridControl.selectionControl.onSelection(row) : null
}

</script>
